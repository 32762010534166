import React from 'react';
import { Layout } from '../../../components';
import EventCreate from '../../../components/EventCreate';

const CreateEvent = () => {
  return (
    <Layout>
      <EventCreate noCancel />
    </Layout>
  );
};

export default CreateEvent;
